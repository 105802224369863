// 0. Mixins
@import "./mixins/navbar";

// 1. Bootstrap
@import "./variables";

// 2. Bootstrap Components Override

// - 2.1. Alert
@import "./components/alert";

// - 2.2. Avatar
@import "./components/avatar";

// - 2.3. Badge
@import "./components/badge";

// - 2.4. Buttons
@import "./components/buttons";

// - 2.5. breadcrumb
@import "./components/breadcrumb";

// - 2.6. carousel
@import "./components/carousel";

// - 2.7. card
@import "./components/card";

// - 2.8. divider
@import "./components/divider";

// - 2.9. dropdowns
@import "./components/dropdowns";

// - 2.10. forms
@import "./components/forms";

// - 2.11. modal
@import "./components/modal";

// - 2.12. sidebar
@import "./components/sidebar";

// - 2.13. navs
@import "./components/navs";

// - 2.14. navbar
@import "./components/navbar";

// - 2.15. pagination
@import "./components/pagination";

// - 2.16. table
@import "./components/table";

// - 2.17. progress
@import "./components/progress";

// - 2.18. icons
@import "./components/icons";

// 4.0 Layout
@import "./layouts/main.scss";

@import "./utilities";

.topbuttons {
  margin: 15px;
}


.o7-button {
  border-color: #fb9800;
  color: #fb9800;
  float: right;

  &:hover {
    background-color: #fb9800;
    border-color: #fb9800;
    color: #fff;
  }
  &:active {
    box-shadow: 0 0 0 0.25rem rgb(251 160 20 / 62%)!important;
  }

  &:focus {
    box-shadow: 0 0 0 0.25rem rgb(251 160 20 / 62%)!important;
  }
}

